import React from 'react';
import classNames from 'classnames';

import Icon from 'common/Icon';
import Typography from 'common/Typography';

import { IconTileProps } from './models';

import './IconTile.scss';

const IconTile = ({
  icon,
  title,
  size,
  as,
  color = 'deep-koamaru',
  weight = 'regular',
  positionMobile = 'left',
  positionDesktop = 'top',
  showMobileVersion = true,
  iconClassName,
}: IconTileProps) => {
  const iconTileClassNames = classNames(
    'icon-tile',
    `icon-tile--${showMobileVersion ? positionMobile : positionDesktop}`
  );

  return (
    <div className={iconTileClassNames}>
      <Icon className={`icon-tile__icon ${iconClassName}`} icon={icon} />
      <Typography
        color={color}
        as={as}
        dangerouslySetInnerHTML={title}
        weight={weight}
        size={size || { base: 16, md: 20 }}
        className="icon-tile__title"
      />
    </div>
  );
};

export default IconTile;
