import React, { FC } from 'react';
import { graphql } from 'gatsby';

import { Container, Layout } from 'layout';
import Banner from 'components/Banner';
import Instagram from 'components/Instagram';
import YoutubeVideo from 'components/YoutubeVideo';
import IconSection from 'containers/IconSection';
import RelatedList from 'containers/RelatedList';
import { articlesVariants, handleArticleVariant } from 'containers/RelatedList/utils';

import './HomePage.scss';

const HomePage: FC<HomePageTypes.HomePageProps> = ({
  data: {
    homePage: {
      urls,
      seo,
      heroBanner,
      infoBanner,
      video,
      ourProducts,
      productCards,
      ourArticles,
      iconSection,
      instagramPosts: instagramContent,
    },
    allArticleDetailPage: { articlesList },
    allInstagramContent,
  },
}) => (
  <Layout className="background--chardon" {...{ urls, seo }}>
    <Banner {...heroBanner} />
    {iconSection ? <IconSection {...{ iconSection }} backgroundColor="chardon" /> : null}
    <Banner {...infoBanner} />

    <Container fluid className="background--chardon">
      <Container className="homepage__youtube" gutter>
        <YoutubeVideo video={video} />
      </Container>
    </Container>
    <RelatedList relatedItems={productCards} {...ourProducts} />
    <RelatedList
      relatedItems={handleArticleVariant(articlesList, articlesVariants.ourArticle)}
      {...ourArticles}
    />
    <Instagram color="white" {...{ instagramContent, posts: allInstagramContent?.nodes }} />
  </Layout>
);
export const query = graphql`
  query HomePageQuery($lang: String, $articlesUrls: [String]) {
    homePage(lang: { eq: $lang }) {
      seo {
        ...SEOStructureFragment
      }
      urls {
        ...UrlsFragment
      }
      iconSection {
        title
        footnote
        icon {
          ...IconListFragment
        }
      }
      heroBanner {
        ...BannerFragment
      }
      infoBanner {
        ...BannerFragment
      }
      video {
        ...YoutubeVideoFragment
      }
      ourProducts {
        ...RelatedListFragment
      }
      productCards {
        id
        card {
          ...CardFragment
        }
        url
        tags {
          ...TagFragment
        }
      }
      ourArticles {
        ...RelatedListFragment
      }

      instagramPosts {
        ...InstagramPostsFragment
      }
    }

    allArticleDetailPage(filter: { url: { in: $articlesUrls } }) {
      articlesList: nodes {
        ...ArticleCardFragment
      }
    }

    allInstagramContent(limit: 4) {
      nodes {
        caption
        permalink
        localFile {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
      }
    }
  }
`;

export default HomePage;
