import React, { FC } from 'react';
import classNames from 'classnames';

import Icon from 'common/Icon';
import Image from 'common/Image';
import Link from 'common/Link';
import Typography from 'common/Typography';

import { trimDotString } from 'utils/stringUtils';
import { useScreenService } from 'hooks/useScreenService';

import { InstagramProps } from './models';

import './Instagram.scss';

const Instagram: FC<InstagramProps> = ({ instagramContent, posts, className, color }) => {
  const { isSm } = useScreenService();

  const trimCaption = (text: string) =>
    trimDotString(text?.replaceAll('\u2028', ''), isSm ? 60 : 280);

  const instagramClass = classNames(
    'instagram',
    {
      [`background--${color}`]: color,
    },
    className
  );

  return posts?.length ? (
    <div className={instagramClass} data-testid="instagram">
      {instagramContent?.heading ? (
        <div className="instagram__heading">
          {instagramContent?.icon ? (
            <Icon className="instagram__heading-icon" icon={instagramContent.icon.icon} />
          ) : null}
          <Typography
            as="p"
            color="deep-koamaru"
            size={{ base: 16, md: 25, lg: 40 }}
            weight="bold"
            align="center"
            className="instagram__heading-text"
          >
            {instagramContent.heading}
          </Typography>
        </div>
      ) : null}

      <div className="instagram__posts" data-testid="instagram__posts">
        {posts.map(({ localFile, permalink, caption }) => (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <Link url={permalink} key={permalink} className="instagram__post">
            <Image alt="instagram__image" className="instagram__image" imageData={localFile} />
            <div className="instagram__caption">
              <Typography as="p" color="white" size={20}>
                {caption ? trimCaption(caption) : null}
              </Typography>
            </div>
          </Link>
        ))}
      </div>
    </div>
  ) : null;
};

export default Instagram;
