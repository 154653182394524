import React, { FC } from 'react';
import classNames from 'classnames';

import { Container } from 'layout';
import Typography from 'common/Typography';
import IconTile from 'components/IconTile';

import { useScreenService } from 'hooks/useScreenService';

import { IconSectionPropsType } from './models';

import './IconSection.scss';

const IconSection: FC<IconSectionPropsType> = ({ iconSection, backgroundColor }) => {
  const { isMobile, isXxl } = useScreenService();

  return (
    <Container
      fluid
      className={classNames('icon-section', {
        [`background--${backgroundColor}`]: backgroundColor,
      })}
    >
      <Container gutter>
        <Typography
          className="icon-section__heading"
          align="center"
          size={{ base: 21, lg: 50 }}
          color="deep-koamaru"
          dangerouslySetInnerHTML={iconSection.title}
        />
        <section className="icon-section__icons-wrapper">
          {iconSection.icon.map(({ icon, title, positionDesktop, positionMobile }) => (
            <IconTile
              icon={icon}
              title={title}
              positionDesktop={positionDesktop || undefined}
              positionMobile={positionMobile || undefined}
              showMobileVersion={isMobile || isXxl}
              weight="bold"
              as="h3"
              size={{ base: 16, md: 25 }}
              key={icon}
            />
          ))}
        </section>
        <Typography
          className="icon-section__footnote"
          size={12}
          dangerouslySetInnerHTML={iconSection.footnote}
        />
      </Container>
    </Container>
  );
};

export default IconSection;
